import React from "react"

export const homeSVG = (
  <svg width="30" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.546 21.692V7.846h2.727v13.385h15.454V7.846h2.727v13.846c0 1.275-1.017 2.308-2.272 2.308H6.818c-1.255 0-2.272-1.033-2.272-2.308z"
      fill="var(--color-text)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.244.233a1.346 1.346 0 011.512 0l13.637 9.23c.626.425.796 1.284.378 1.92a1.351 1.351 0 01-1.891.385L15 3.047l-12.88 8.72a1.351 1.351 0 01-1.89-.385 1.398 1.398 0 01.377-1.92L14.244.233z"
      fill="var(--color-text)"
    />
    <path
      d="M12.273 15.23c0-.509.407-.922.909-.922h3.636c.502 0 .91.413.91.923v6.461c0 .51-.408.923-.91.923h-3.636a.916.916 0 01-.91-.923v-6.461z"
      fill="var(--color-text)"
    />
  </svg>
)

export const recipesSVG = (
  <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.086 14.796a.487.487 0 01.686 0l3.716 3.697c.19.189.19.495 0 .683a.487.487 0 01-.686 0l-3.716-3.697a.481.481 0 010-.683zM3.885 17.014a.487.487 0 01.686 0l2.972 2.958c.19.189.19.494 0 .683a.487.487 0 01-.686 0l-2.972-2.958a.481.481 0 010-.683z"
      fill="var(--color-text)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.139 18.152L4.494 25.526C1.868 27.19-1.195 24.141.476 21.528L7.886 9.94a2.919 2.919 0 014.513-.493l4.235 4.215a2.888 2.888 0 01-.495 4.49zm-1.042-1.63L3.452 23.898c-.875.555-1.896-.461-1.34-1.332l7.411-11.589a.973.973 0 011.505-.164l4.234 4.214a.963.963 0 01-.165 1.497z"
      fill="var(--color-text)"
    />
    <path
      d="M19.484 0C17.368.42 14.19 4.508 16.2 7.06 18.212 9.61 21.913 5.17 19.485 0zM26 9.443c-.503 2.764-4.486 5.272-7.945 3.62-3.936-1.878 1.039-5.625 7.945-3.62z"
      fill="var(--color-text)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.496 10.966c.927-.261 1.957-.197 2.624-.064l-.19.946c-.572-.113-1.437-.16-2.17.047-.71.2-1.23.61-1.342 1.397l-.96-.136c.184-1.283 1.09-1.922 2.038-2.19zM17.049 8.92c.692-1.19.93-2.559 1.054-3.296l-.957-.159c-.124.742-.342 1.95-.936 2.97-.578.992-1.493 1.782-3.065 1.782v.966c1.995 0 3.195-1.047 3.904-2.264z"
      fill="var(--color-text)"
    />
  </svg>
)

export const resourcesSVG = (
  <svg width="29" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.45 0c.8 0 1.45.645 1.45 1.44v19.68h24.65c.8 0 1.45.645 1.45 1.44 0 .795-.65 1.44-1.45 1.44H1.45C.65 24 0 23.355 0 22.56V1.44C0 .645.65 0 1.45 0z"
      fill="var(--color-text)"
    />
    <path
      d="M7.25 10.08c0-.53.433-.96.967-.96h2.9c.533 0 .966.43.966.96v8.64c0 .53-.433.96-.966.96h-2.9a.963.963 0 01-.967-.96v-8.64zM14.017 3.36c0-.53.433-.96.966-.96h2.9c.534 0 .967.43.967.96v15.36c0 .53-.433.96-.967.96h-2.9a.963.963 0 01-.966-.96V3.36zM20.783 8.16c0-.53.433-.96.967-.96h2.9c.534 0 .967.43.967.96v10.56c0 .53-.433.96-.967.96h-2.9a.963.963 0 01-.967-.96V8.16z"
      fill="var(--color-text)"
    />
  </svg>
)

export const blogSVG = (
  <svg width="22" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.398 2.559l3.115 3.204-7.734 7.955a.88.88 0 01-.251.18l-3.502 1.68c-.745.358-1.518-.437-1.17-1.204l1.632-3.602a.906.906 0 01.176-.258l7.734-7.955zM18.627.265a.864.864 0 011.246 0l1.869 1.923a.925.925 0 010 1.282l-1.516 1.56-3.115-3.205 1.516-1.56z"
      fill="var(--color-text)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.686 4.156H1.762C.79 4.156 0 4.967 0 5.968v17.22C0 24.188.789 25 1.762 25H16.74c.973 0 1.762-.811 1.762-1.813V9.893l-2.643 2.718v9.67H2.643V6.875h6.4l2.643-2.72z"
      fill="var(--color-text)"
    />
  </svg>
)

export const shopsSVG = (
  <svg width="32" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.562 8.16H7.118l6.52-6.521A.96.96 0 0012.282.28L4.402 8.16H1.44C.645 8.16 0 8.16 0 9.6s.645 1.44 1.44 1.44h1.063L4.94 22.42A2 2 0 006.897 24h17.886a2 2 0 001.956-1.58l2.438-11.38h1.063c.795 0 1.44 0 1.44-1.44s-.645-1.44-1.44-1.44h-2.962L19.398.281a.96.96 0 10-1.357 1.358l6.521 6.521zm-2.002 5.28a.96.96 0 100-1.92H8.16a.96.96 0 100 1.92h14.4zm0 2.88c0 .53-.43.96-.96.96H9.12a.96.96 0 110-1.92H21.6c.53 0 .96.43.96.96zm-1.92 4.8a.96.96 0 100-1.92H10.08a.96.96 0 100 1.92h10.56z"
      fill="var(--color-text)"
    />
  </svg>
)

export const tickSVG = (
  <svg
    role="img"
    aria-label="Tick icon"
    width="19"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5343 18C14.5049 18 18.5343 13.9706 18.5343 9C18.5343 4.02944 14.5049 0 9.5343 0C4.56374 0 0.534302 4.02944 0.534302 9C0.534302 13.9706 4.56374 18 9.5343 18ZM14.9097 6.3139C15.3475 5.78014 15.2698 4.99252 14.736 4.55469C14.2022 4.11686 13.4146 4.19462 12.9768 4.72838L7.8973 10.9207L5.99751 8.94558C5.51894 8.44802 4.72764 8.43263 4.23008 8.9112C3.73252 9.38977 3.71713 10.1811 4.1957 10.6786L7.07067 13.6677C7.31824 13.9251 7.66407 14.0643 8.02092 14.0502C8.37776 14.0361 8.71154 13.87 8.93803 13.5939L14.9097 6.3139Z"
      fill="var(--color-positive)"
    />
  </svg>
)

export const crossSVG = (
  <svg
    role="img"
    aria-label="Cross icon"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18ZM14.1274 4.36616C14.6155 4.85437 14.6155 5.64591 14.1274 6.13412L11.2665 8.99563L14.2218 11.9494C14.7101 12.4374 14.7101 13.2286 14.2218 13.7167C13.7335 14.2047 12.9419 14.2047 12.4536 13.7167L9.49894 10.7636L6.63365 13.6295C6.14555 14.1177 5.35418 14.1177 4.86608 13.6295C4.37797 13.1413 4.37797 12.3498 4.86608 11.8616L7.7307 8.9963L4.95683 6.22392C4.46854 5.7359 4.46854 4.94466 4.95683 4.45663C5.44511 3.96861 6.23678 3.96861 6.72507 4.45663L9.49827 7.22834L12.3598 4.36616C12.8479 3.87795 13.6393 3.87795 14.1274 4.36616Z"
      fill="var(--color-negative)"
    />
  </svg>
)

export const plusSVG = (
  <svg
    role="img"
    aria-label="Addition icon"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9C18 13.9706 13.9706 18 9 18C4.02945 18 0 13.9706 0 9C0 4.02943 4.02945 0 9 0C13.9706 0 18 4.02943 18 9ZM8 4C8 3.44772 8.44772 3 9 3C9.55228 3 10 3.44772 10 4V8H14C14.5523 8 15 8.44772 15 9C15 9.55228 14.5523 10 14 10H10V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V10H4C3.44772 10 3 9.55228 3 9C3 8.44772 3.44772 8 4 8H8V4Z"
      fill="var(--color-settingsIcon)"
    />
  </svg>
)

export const minusSVG = (
  <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18A9 9 0 109 0a9 9 0 000 18zm6-9a1 1 0 00-1-1H4a1 1 0 100 2h10a1 1 0 001-1z"
      fill="var(--color-settingsIcon)"
    />
  </svg>
)

export const prepTimeSVG = (
  <svg
    role="img"
    aria-label="Temps de préparation"
    style={{ marginRight: "-3px" }}
    width="25"
    height="23"
    fill="none"
    opacity="0.9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.95.266L22.503 0 2.101 20.147l.667.46c2.196 1.517 4.261 2.028 6.02 1.06.357-.196.745-.506 1.094-.802.177-.15.354-.306.523-.454l.007-.007c.173-.152.334-.294.485-.421 2.93-2.474 5.406-4.624 7.429-6.451l.534-.483-.518-.5c-.888-.855-1.371-1.533-1.554-2.036-.087-.242-.095-.414-.072-.537a.663.663 0 0 1 .211-.36 1.66 1.66 0 0 1 .257-.17c.145-.085.32-.177.522-.285l.025-.013c.407-.215.92-.488 1.33-.796.17-.13.378-.36.563-.573a46.043 46.043 0 0 0 .68-.803c.5-.595 1.002-1.171 1.365-1.471.416-.345 1.373-.952 2.927-1.836L25 3.44l-.073-.459a4.12 4.12 0 0 0-.465-1.339C24.176 1.12 23.627.67 22.95.266zM15.21 9.09L4.218 19.944c1.696 1 2.963 1.075 3.923.547.227-.125.524-.354.872-.65.168-.142.338-.292.509-.442l.003-.003c.17-.15.343-.301.506-.44a314.027 314.027 0 0 0 6.873-5.951c-.667-.712-1.145-1.391-1.378-2.035-.148-.408-.208-.83-.13-1.243.024-.122.058-.238.102-.35l-.288-.287z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.974 1.662c1.906 1.905 2.19 5.075 2.19 6.274 0 1.199 12.859 12.53 12.859 12.53a.876.876 0 0 1 0 1.239l-.184.183a.876.876 0 0 1-1.238 0S9.164 9.135 7.847 9.135c-1.577 0-4.221-.008-6.223-2.01C-.711 4.79-.25 2.898 1.259 1.389c1.509-1.508 3.38-2.062 5.715.273z"
      fill="currentColor"
    />
  </svg>
)

export const cookTimeSVG = (
  <svg
    role="img"
    aria-label="Temps de cuisson"
    width="21"
    height="23"
    fill="none"
    opacity="0.9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3a3 3 0 013-3h15a3 3 0 013 3v19a1 1 0 01-1 1H1a1 1 0 01-1-1V3zm3-1h15a1 1 0 011 1v3H2V3a1 1 0 011-1zM2 8v13h17V8H2z"
      fill="var(--color-text)"
    />
    <circle cx="5" cy="4" r="1" fill="var(--color-text)" />
    <circle cx="8" cy="4" r="1" fill="var(--color-text)" />
    <circle cx="16" cy="4" r="1" fill="var(--color-text)" />
    <circle cx="13" cy="4" r="1" fill="var(--color-text)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10a1 1 0 00-1 1v7a1 1 0 001 1h11a1 1 0 001-1v-7a1 1 0 00-1-1H5zm1.5 2a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-8z"
      fill="var(--color-text)"
    />
  </svg>
)

export const arrowSVG = (
  <svg
    role="img"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17939e-07 10.5C1.42503e-06 16.3004 4.6996 21 10.5 21C16.3004 21 21 16.3004 21 10.5C21 4.6996 16.3004 4.10852e-07 10.5 9.17939e-07C4.6996 1.42503e-06 4.10852e-07 4.6996 9.17939e-07 10.5ZM9.78024 15.3224L4.04335 9.58549C3.64536 9.1875 3.64536 8.54395 4.04335 8.1502L4.76311 7.43045C5.16109 7.03246 5.80464 7.03246 6.19839 7.43045L10.1464 11.3785C10.3417 11.5738 10.6583 11.5738 10.8536 11.3785L14.8016 7.43044C15.1996 7.03246 15.8431 7.03246 16.2369 7.43044L16.9567 8.1502C17.3546 8.54819 17.3546 9.19173 16.9567 9.58548L11.2198 15.3224C10.8218 15.7204 10.1782 15.7204 9.78024 15.3224Z"
      fill="var(--color-text)"
    />
  </svg>
)

export const searchSVG = (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      r="3.6"
      transform="matrix(-1 0 0 1 4.5 4.5)"
      stroke="var(--color-navText)"
      strokeWidth="1.8"
    />
    <line
      x1="0.9"
      y1="-0.9"
      x2="6.17107"
      y2="-0.9"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 7 6)"
      stroke="var(--color-navText)"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const infoSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM10.0238 15.669C10.2937 15.4353 10.4286 15.1497 10.4286 14.8122V6.77051C10.4286 6.43301 10.2937 6.15392 10.0238 5.93324C9.75397 5.69958 9.4127 5.58275 9 5.58275C8.5873 5.58275 8.24603 5.69958 7.97619 5.93324C7.70635 6.15392 7.57143 6.43301 7.57143 6.77051V14.8122C7.57143 15.1497 7.70635 15.4353 7.97619 15.669C8.24603 15.8897 8.5873 16 9 16C9.4127 16 9.75397 15.8897 10.0238 15.669ZM7.83333 4.10292C8.05556 4.24571 8.43651 4.31711 8.97619 4.31711C9.51587 4.31711 9.90476 4.2522 10.1429 4.12239C10.381 3.9796 10.5 3.71998 10.5 3.34353V2.97357C10.5 2.61011 10.3889 2.35698 10.1667 2.21419C9.94444 2.0714 9.55556 2 9 2C8.49206 2 8.11111 2.0714 7.85714 2.21419C7.61905 2.344 7.5 2.59713 7.5 2.97357V3.34353C7.5 3.707 7.61111 3.96013 7.83333 4.10292Z"
      fill="var(--color-settingsIcon)"
    />
  </svg>
)
