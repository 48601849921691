export const lightTheme = {
  text: "#0c1635",
  background: "#EEE4D9",
  nav: "#fff",
  navTitle: "#B36464",
  navText: "#9D5A5A",
  settings: "#393f51",
  settingsIcon: "#bd7b6e",
  altColor: "#02799d",
  graphBackground: "#fff",
  hr: "hsla(7.6, 52.3%, 61.4%, 0.5)",
  activeLink: "#ce8273",
  vegan: "#539561",
  veganOption: "#1670bf",
  vegetarian: "#b36464",
  appBar: "#fff9f1",
  appBarDropShadow: "hsla(0, 0%, 10%, 0.4)",
  activeAppTab: "#fff",
  appBarHighlight: "#ce8273",
  positive: "#4F9F5C",
  negative: "#cc5c5c",
  footer: "hsla(8.6, 35.4%, 69%, 0.5)",
  navDropDown: "#EEE4D9",
  navDropDownHover: "#733029",
  searchBackground: "#FFF",
  searchListTransparent: "#ffd2c800",
  searchListSelected: "#FFE2DB",
  searchListHover: "#FFEAE6",
  searchShadow: "0, 0%, 0%",
  filterSectionA: "#B55E5E",
  landingH1: "#9D4646",
  landingH2: "#742D2D",
  landingUnderline: "#D3AAA4",
  landingBgAccent: "#E5D3C1",
  landingSpotlight: "#491223",
}

export const darkTheme = {
  text: "#ddd",
  background: "#272830",
  nav: "hsla(234, 11%, 22%, 0.98)",
  navTitle: "#ddd",
  navText: "#ddd",
  settings: "#1b1c22",
  settingsIcon: "#b77567",
  altColor: "#ce8273",
  graphBackground: "#0e0e11",
  hr: "hsla(0, 0%, 86%, 0.3)",
  activeLink: "#d59386",
  vegan: "#a6e2b7",
  veganOption: "#a3d8e0",
  vegetarian: "#f5b575",
  appBar: "#2b2c36",
  activeAppTab: "#32333e",
  appBarHighlight: "#ddd",
  appBarDropShadow: "#1a1a1a",
  positive: "#88da81",
  negative: "#f96767",
  footer: "hsla(0, 0%, 0%, 0.2)",
  navDropDown: "#26252b",
  navDropDownHover: "#58dba2",
  searchBackground: "#17181c",
  searchListTransparent: "#2c354400",
  searchListSelected: "#2c3544",
  searchListHover: "#384357",
  searchShadow: "0, 0%, 100%",
  filterSectionA: "#a6e2b7",
  landingH1: "#ddd",
  landingH2: "#ddd",
  landingUnderline: "#B36464",
  landingBgAccent: "#2D3443",
  landingSpotlight: "#13161E",
}
